import React, { useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { GeneContext } from "../../../contexts/GeneContext";
import { GeneNumericInput } from "../common/NumericInput";
import GenePaneHeader from "../common/GenePaneHeader";
import GeneSelect from "../common/GeneSelect";
import Alert from "../../common/Alert";

const Pm1Rows = () => {
  const {
    changeSingleGeneValue,
    gene,
    resetFieldsToDefaults,
    germlineDefaults,
  } = useContext(GeneContext);

  const onResetHotspot = () => {
    const criteria = [
      "hotspot_distance",
      "hotspot_strength",
      "hotspot_threshold",
      "disable_pm5_when_pm1_present",
    ];
    resetFieldsToDefaults(criteria);
  };

  const resolvedValue = (field: string) => {
    return gene[field] !== null ? gene[field] : germlineDefaults[field];
  };

  return (
    <>
      <GenePaneHeader
        includeApply={false}
        headers={[]}
        onResetDefaults={onResetHotspot}
      />
      <Row>
        <Col>
          <Form.Check
            aria-label="disable_pm5_when_pm1_present"
            name="disable_pm5_when_pm1_present"
            className="display-inline mr-2"
            label="Disable PM5 application in presence of PM1"
            type="checkbox"
            checked={resolvedValue("disable_pm5_when_pm1_present")}
            onChange={(e) => {
              changeSingleGeneValue(e);
            }}
          />
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col className="font-weight-bold col-sm-4 mr-2">
          PM1 hotspot distance
        </Col>
        <GeneNumericInput field={"hotspot_distance"} min={1} size={4} />
        <Alert />
      </Row>
      <Row className="align-items-center">
        <Col className="font-weight-bold col-sm-4 mr-2">
          PM1 hotspot threshold
        </Col>
        <GeneNumericInput field={"hotspot_threshold"} min={1} size={4} />
        <Alert />
      </Row>
      <Row>
        <GeneSelect field="hotspot_strength" titleSize={4}></GeneSelect>
      </Row>
    </>
  );
};

export default Pm1Rows;
