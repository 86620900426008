import React, { useContext } from "react";
import { Col, Form } from "react-bootstrap";
import { GeneContext } from "../../../contexts/GeneContext";

interface BaseNumericInputProps {
  allowDecimals: boolean;
  applied: boolean;
  field: string;
  placeholder?: string;
  min: number;
  max?: number;
  required: boolean;
  size: number;
  step: number;
}

interface NumericInputProps extends BaseNumericInputProps {
  asFormGroup: boolean;
  error: string;
  onChange: (e: any) => void;
  value: number | null;
}

const NumericInput = (props: NumericInputProps) => {
  const {
    allowDecimals,
    asFormGroup,
    applied,
    error,
    field,
    onChange,
    placeholder,
    min,
    max,
    required,
    size,
    step,
    value,
  } = props;

  const composeOnKeyDown = (forbiddenKeys: string[]) => {
    return (e: any) => {
      if (forbiddenKeys.includes(e.key)) e.preventDefault();
    };
  };

  const baseForbiddenKeys = ["e", "-", "+"];
  const options: Record<string, any> = { min, step };
  if (max) options["max"] = max;
  options["onKeyDown"] = allowDecimals
    ? composeOnKeyDown(baseForbiddenKeys)
    : composeOnKeyDown(baseForbiddenKeys.concat(["."]));

  const renderInput = () => {
    const input = (
      <Form.Control
        aria-label={field}
        type="number"
        name={field}
        onChange={onChange}
        placeholder={placeholder || ""}
        value={value === 0 ? value : !!value ? value : ""}
        disabled={!applied}
        required={required && applied}
        className={!!error ? "is-invalid" : ""}
        {...options}
      />
    );

    if (asFormGroup) {
      return (
        <Form.Group as={Col} sm={size} className="mr-2">
          {input}
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
      );
    }

    return input;
  };

  return renderInput();
};

NumericInput.defaultProps = {
  allowDecimals: false,
  asFormGroup: true,
  applied: true,
  error: "",
  required: false,
  size: 3,
  step: 1,
};

export default NumericInput;

export const GeneNumericInput = (props: BaseNumericInputProps) => {
  const { changeSingleGeneValue, errorState, gene, germlineDefaults } =
    useContext(GeneContext);

  const placeholder =
    props.field in germlineDefaults ? germlineDefaults[props.field] : "";

  const value =
    props.field in germlineDefaults
      ? germlineDefaults[props.field] === gene[props.field]
        ? null
        : gene[props.field]
      : gene[props.field];

  return (
    <NumericInput
      {...props}
      onChange={changeSingleGeneValue}
      placeholder={placeholder}
      error={errorState[props.field]}
      value={value}
    />
  );
};

GeneNumericInput.defaultProps = {
  allowDecimals: false,
  applied: true,
  required: false,
  size: 3,
  step: 1,
};
