import React, { useContext } from "react";
import { Col, Form } from "react-bootstrap";
import { GeneContext } from "../../../contexts/GeneContext";

interface GeneSelectProps {
  field: string;
  titleSize: number;
}

const GeneSelect = (props: GeneSelectProps) => {
  const { field, titleSize } = props;
  const {
    changeSingleGeneValue,
    errorState,
    gene,
    germlineDefaults,
    strengthOptions,
  } = useContext(GeneContext);

  return (
    <>
      <Col className={`font-weight-bold col-sm-${titleSize} mr-2`}>
        {strengthOptions[field].description}
      </Col>
      <Form.Group as={Col} sm={4}>
        <Form.Control
          aria-label={field}
          as="select"
          name={field}
          onChange={changeSingleGeneValue}
          value={gene[field] || germlineDefaults[field]}>
          {strengthOptions[field].options.map((option) => {
            return (
              <option
                key={option.value}
                data-testid={option.value}
                value={option.value}>
                {option.description}
              </option>
            );
          })}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errorState[field]}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

GeneSelect.defaultProps = {
  titleSize: 2,
};

export default GeneSelect;
